<template>
  <div>
    <Header></Header>
    <BGImg></BGImg>
    <div class="content">123</div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import BGImg from "@/components/BGImg";

export default {
  name: "test.vue",
  components:{
    BGImg,
    Footer,
    Header,
  }
}
</script>

<style scoped>
.content{
  height: 1500px;
}
</style>
